import { MobileProfileButton } from '@mfe/shared/components';

import AlertsSection from './alerts';
import ArticleBanner from './article-banner';
import BalanceSummary from './billing-summary';
import BillingCommonQuestions from './common-questions';
import InvoiceHistory from './invoice-history';

import styles from './home.module.scss';

const BillingHome = (): JSX.Element => {
  return (
    <div style={{ flex: 1 }}>
      <MobileProfileButton />

      <div data-cy="billing-page" className={styles['container']}>
        <AlertsSection />
        <div data-cy="billing-body" className={styles['bodyGrid']}>
          <div className={styles['balanceSummaryMargin']}>
            <BalanceSummary />
          </div>

          <div className={styles['commonQuestionsMargin']}>
            <BillingCommonQuestions />
          </div>

          <div className={styles['rightSide']}>
            <div className={styles['invoiceMargin']}>
              <InvoiceHistory />
            </div>

            <div className={styles['articleMargin']}>
              <ArticleBanner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHome;
