import { AccountType, Locale } from '@mfe/shared/schema-types';

const DEFAULT_EXTENSION = 'html';
// syndicated causes imports to be absolute instead of relative to viasat.com
// nobeam stops beam from being imported, so we don't import it twice
// min causes AEM to return a partial HTML fragment.
const REQUIRED_SELECTORS: Selectors[] = ['syndicated', 'min', 'nobeam'];
export const SKIP_PARAMETER_CONSTANT = 'none';

enum AppName {
  Buy = 'buy',
  My = 'my',
}

export enum AemFeature {
  PLAN_LABEL = 'plan-label',
  MV_APP_AD = 'ads/mv-app-ad',
  MV_SHIELD_AD = 'ads/shield-ad',
  UNLEASHED_USAGE_GREEN = 'usage-component/usage-component-green',
  UNLEASHED_USAGE_YELLOW = 'usage-component/usage-component-yellow',
  UNLEASHED_USAGE_RED = 'usage-component/usage-component-red',
  ADD_ON_EASYCARE = 'add-ons/easycare',
  ADD_ON_SHIELD = 'add-ons/viasat-shield',
  ADD_ON_STATIC_IP = 'add-ons/static-ip',
  ADD_ON_VOICE = 'add-ons/viasat-voice',
  OVERVIEW_COMMON_QUESTIONS = 'commonq/fsi-overview',
  BILLING_COMMON_QUESTIONS = 'commonq/fsi-billing',
  USAGE_COMMON_QUESTIONS = 'commonq/fsi-usage',
  NETWORK_COMMON_QUESTIONS = 'commonq/fsi-network',
  /*
   * TODO: Do we have an AEM fragment for Office Hours?
   *
   * As part of VGFB-729, no AEM fragment URL was
   * provided for the Office Hours add-on
   */
}

const featureToAppName: { [key in AemFeature]: AppName } = {
  [AemFeature.PLAN_LABEL]: AppName.Buy,
  [AemFeature.MV_APP_AD]: AppName.My,
  [AemFeature.MV_SHIELD_AD]: AppName.My,
  [AemFeature.UNLEASHED_USAGE_GREEN]: AppName.My,
  [AemFeature.UNLEASHED_USAGE_YELLOW]: AppName.My,
  [AemFeature.UNLEASHED_USAGE_RED]: AppName.My,
  [AemFeature.ADD_ON_EASYCARE]: AppName.My,
  [AemFeature.ADD_ON_SHIELD]: AppName.My,
  [AemFeature.ADD_ON_STATIC_IP]: AppName.My,
  [AemFeature.ADD_ON_VOICE]: AppName.My,
  [AemFeature.OVERVIEW_COMMON_QUESTIONS]: AppName.My,
  [AemFeature.BILLING_COMMON_QUESTIONS]: AppName.My,
  [AemFeature.USAGE_COMMON_QUESTIONS]: AppName.My,
  [AemFeature.NETWORK_COMMON_QUESTIONS]: AppName.My,
};

export type Selectors = 'pidi' | 'syndicated' | 'min' | 'nobeam' | 'master';

export type AemUrlProps = {
  baseUrl: string;
  locale: Locale;
  feature: AemFeature;
  additionalSelectors?: Selectors[];
  extension?: string;
  accountType?: AccountType;
  suffixes?: { [key: string]: string | null };
};

export const createAemUrl = ({
  baseUrl,
  locale,
  feature,
  extension = DEFAULT_EXTENSION,
  additionalSelectors = [],
  accountType = AccountType.Residential,
  suffixes = {},
}: AemUrlProps) => {
  const selectors = [...additionalSelectors, ...REQUIRED_SELECTORS];

  const customerType =
    accountType === AccountType.Residential ? 'residential' : 'business';

  const [languageCode, countryCode] = locale.split('_');
  const localePath = `${countryCode.toLowerCase()}/${languageCode}`;

  const fragmentPath = `${featureToAppName[feature]}/${customerType}/${feature}`;
  const selectorsPath = selectors.join('.');

  let suffixPath = '';
  //for all suffixes, if the value is falsy, replace it with 'none'
  Object.keys(suffixes).forEach((key) => {
    if (!suffixes[key]) suffixes[key] = SKIP_PARAMETER_CONSTANT;
    suffixPath += `${suffixes[key]}/`;
  });

  return `${baseUrl}/${localePath}/${fragmentPath}.${selectorsPath}.${extension}/${suffixPath}`;
};
