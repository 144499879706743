import { Button, EmptyState, Surface } from '@vst/beam';
import {
  AEMContentProvider,
  AemFeature,
  createAemUrl,
} from '../AEM-content-provider';
import { useDispatch, useSelector } from 'react-redux';
import useNavigate from '@mfe/services/navigation';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectConfig } from '@mfe/shared/redux/config';

import { AccountType, Locale } from '@mfe/shared/schema-types';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import styles from './common-questions.module.scss';
import { Alert } from '@vst/beam-icons/illustrative-icons';

export const CommonQuestions = ({
  feature,
  section,
}: {
  feature: AemFeature;
  section: string;
}) => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const { t } = useTranslation('Global');

  const [aemFragmentLoading, setAEMFragmentLoading] = useState(true);
  const [aemFragmentError, setAEMFragmentError] = useState(false);

  const { aemBaseUrl, loadingConfig } = useSelector(selectConfig);

  const {
    loading: loadingUserInfo,
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const loading = loadingConfig || loadingUserInfo;

  const aemURL = createAemUrl({
    baseUrl: aemBaseUrl,
    locale: userLocale,
    feature,
    accountType,
  });

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const navigateToFaq = (e: React.MouseEvent<HTMLElement>) => {
    const url = t('SMBSupportLink');

    accountType === AccountType.Smb
      ? openLinkInNewTab(e, url)
      : goTo(HelpLink.Main, { section: section });
  };

  if (loading) {
    return <QuestionsLoading />;
  }

  if (aemFragmentError && !aemFragmentLoading && !loading) {
    return <CommonQuestionsError />;
  }

  const showFAQButton =
    userLocale === Locale.EnUs && !aemFragmentLoading && !aemFragmentError;

  return (
    <>
      <AEMContentProvider
        src={aemURL}
        onLoad={() => setAEMFragmentLoading(false)}
        onError={() => {
          setAEMFragmentError(true);
        }}
      />
      {showFAQButton && (
        <Button
          fluid
          variant="secondary"
          mt="16px"
          onClick={(e) => navigateToFaq(e)}
        >
          {t('viewFaqs')}
        </Button>
      )}
    </>
  );
};

const QuestionsLoading = () => {
  return (
    <div className={styles['loadingContainer']}>
      <div className={styles['loadingTitle']} />
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
      <div className={styles['loadingButton']} />
    </div>
  );
};

const CommonQuestionsError = () => {
  const { t } = useTranslation('Global');
  return (
    <Surface
      variant="secondary"
      py={'40px'}
      radius="16px"
      className={styles['commonQuestionsError']}
    >
      <EmptyState icon={Alert} title={t('CommonQuestionsError')} />
    </Surface>
  );
};
