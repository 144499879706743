import { Surface } from '@vst/beam';

import styles from './styles.module.scss';
import {
  AemFeature,
  CommonQuestions,
  HelpPageSectionValue,
} from '@mfe/shared/components';

export const NetworkCommonQuestions = () => {
  return (
    <Surface className={styles['questions']}>
      <CommonQuestions
        feature={AemFeature.NETWORK_COMMON_QUESTIONS}
        section={HelpPageSectionValue.Connectivity}
      />
    </Surface>
  );
};
