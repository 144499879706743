export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export enum AccountType {
  Residential = 'Residential',
  Smb = 'SMB',
}

export type AdditionalDetails = {
  billingAccount: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  addressLines: Array<Scalars['String']>;
  countryCode: Scalars['String'];
  municipality: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region: Scalars['String'];
};

export type AddressGroup = {
  __typename?: 'AddressGroup';
  billing?: Maybe<Address>;
  primary?: Maybe<Address>;
  service?: Maybe<Address>;
  shipping?: Maybe<Address>;
};

export type AddressInput = {
  addressLines: Array<Scalars['String']>;
  countryCode: Scalars['String'];
  labels: Array<ContactMethodLabels>;
  municipality: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
};

export type AggregatedBillingInfo = {
  __typename?: 'AggregatedBillingInfo';
  autopayMethod?: Maybe<AutopayMethod>;
  billingAccount?: Maybe<BillingAccount>;
  invoices?: Maybe<Array<Invoice>>;
};

export type Amount = {
  __typename?: 'Amount';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['Float']>;
};

export type Assessment = {
  __typename?: 'Assessment';
  isOK: Scalars['Boolean'];
  name: AssessmentName;
};

export enum AssessmentName {
  Billing = 'Billing',
  CurrentWeatherStatus = 'CurrentWeatherStatus',
  DataUsage = 'DataUsage',
  DishAlignment = 'DishAlignment',
  DishToModemConnection = 'DishToModemConnection',
  ServiceConfiguration = 'ServiceConfiguration',
  ViasatNetwork = 'ViasatNetwork',
  WifiModem = 'WifiModem',
}

export enum AssessmentValue {
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export type AutopayMethod = {
  __typename?: 'AutopayMethod';
  ccExpMonth?: Maybe<Scalars['Int']>;
  ccExpYear?: Maybe<Scalars['Int']>;
  ccType?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  last4Digits?: Maybe<Scalars['String']>;
  nameOnCard?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentProduct?: Maybe<Scalars['String']>;
  useForRecurringPayment?: Maybe<Scalars['Boolean']>;
};

export type AvailablePhoneNumber = {
  __typename?: 'AvailablePhoneNumber';
  phoneNumber: Scalars['String'];
  prefix: Scalars['String'];
};

export enum BadgeStatus {
  AwaitingPayment = 'AwaitingPayment',
  NoPaymentDue = 'NoPaymentDue',
  Paid = 'Paid',
  PastDue = 'PastDue',
  Pending = 'Pending',
}

export type BillCycleData = {
  __typename?: 'BillCycleData';
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  usageData?: Maybe<Array<Maybe<UsagePerDay>>>;
};

export type BillCycleDaysPayload = {
  __typename?: 'BillCycleDaysPayload';
  billingCycleDayOfMonthUpdateDate?: Maybe<Scalars['String']>;
  canChangeBillDate: Scalars['Boolean'];
  days: Array<Maybe<Scalars['Int']>>;
  reason?: Maybe<BillCycleErrorTypes>;
};

export enum BillCycleErrorTypes {
  AccountNotActive = 'AccountNotActive',
  BillDateIsToday = 'BillDateIsToday',
  NoValidPaymentMethod = 'NoValidPaymentMethod',
  OutstandingBalance = 'OutstandingBalance',
  RecentlyChanged = 'RecentlyChanged',
}

export type BillingAccount = {
  __typename?: 'BillingAccount';
  accountNumber?: Maybe<Scalars['String']>;
  accountStatus?: Maybe<Scalars['String']>;
  balance?: Maybe<Money>;
  billDate?: Maybe<Scalars['String']>;
  billingCycleDayOfMonth?: Maybe<Scalars['Int']>;
  billingStatus?: Maybe<BillingStatus>;
  creditClass?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  invoiceAmount?: Maybe<Money>;
  nextBillPeriodStartDate?: Maybe<Scalars['String']>;
  payDate?: Maybe<Scalars['String']>;
  paymentDueDays?: Maybe<Scalars['Int']>;
};

export enum BillingStatus {
  Current = 'CURRENT',
  New = 'NEW',
  Past = 'PAST',
  Ready = 'READY',
}

export type Boleto = {
  __typename?: 'Boleto';
  billingAccount?: Maybe<Scalars['String']>;
  boletoBarCode?: Maybe<Scalars['String']>;
  boletoDueDate?: Maybe<Scalars['String']>;
  boletoExpDate?: Maybe<Scalars['String']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  invoiceNum?: Maybe<Scalars['String']>;
  invoiceSequenceNumber?: Maybe<Scalars['String']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BuyMoreAmount = {
  __typename?: 'BuyMoreAmount';
  currency?: Maybe<BuyMoreCurrency>;
  value?: Maybe<Scalars['Float']>;
};

export type BuyMoreCharacteristics = {
  __typename?: 'BuyMoreCharacteristics';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BuyMoreCurrency = {
  __typename?: 'BuyMoreCurrency';
  alphabeticCode?: Maybe<Scalars['String']>;
  majorUnitSymbol?: Maybe<Scalars['String']>;
};

export type BuyMorePrice = {
  __typename?: 'BuyMorePrice';
  amount?: Maybe<BuyMoreAmount>;
};

export type BuyMoreProducts = {
  __typename?: 'BuyMoreProducts';
  characteristics?: Maybe<Array<Maybe<BuyMoreCharacteristics>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  productStartDate?: Maybe<Scalars['String']>;
};

export type CartItems = {
  __typename?: 'CartItems';
  plan?: Maybe<CartProduct>;
};

export type CartProduct = {
  __typename?: 'CartProduct';
  accountSetupFeePrice?: Maybe<Scalars['Float']>;
  accountSetupFeeShortName?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  contractTerms?: Maybe<Scalars['String']>;
  contractTermsDisclosure?: Maybe<Scalars['String']>;
  leaseFeePrice?: Maybe<Scalars['Float']>;
  leaseFeeShortName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type Characteristic = {
  __typename?: 'Characteristic';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Characteristics = {
  __typename?: 'Characteristics';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CheckStreamEligibilityPayload = {
  __typename?: 'CheckStreamEligibilityPayload';
  error?: Maybe<StreamEligibilityError>;
  isEligible?: Maybe<Scalars['Boolean']>;
};

export type ConfidantConfig = {
  __typename?: 'ConfidantConfig';
  aemBaseUrl: Scalars['String'];
  aemContentBaseUrl: Scalars['String'];
  allowAnalytics: Scalars['Boolean'];
  analyticsUrl: Scalars['String'];
  disableResidentialAddons?: Maybe<Array<Scalars['String']>>;
  disableSmbAddons?: Maybe<Array<Scalars['String']>>;
  env: Scalars['String'];
  getMockTier: Scalars['Boolean'];
  inMaintenance: Scalars['Boolean'];
  setACHasDefault: Scalars['Boolean'];
  showACPBanner: Scalars['Boolean'];
  showAddonsShop: Scalars['Boolean'];
  showBroadbandLabels: Scalars['Boolean'];
  showChangePlanSMB: Scalars['Boolean'];
  showChat: Scalars['Boolean'];
  showDevicesGraph: Scalars['Boolean'];
  showDonutChart: Scalars['Boolean'];
  showGroupedEasyCare: Scalars['Boolean'];
  showNetworkPage: Scalars['Boolean'];
  showOverviewRedesign: Scalars['Boolean'];
  showPaypalPaymentOption: Scalars['Boolean'];
  showPreAuthDisclaimer: Scalars['Boolean'];
  showRemoveAddonFlow: Scalars['Boolean'];
  showUnleashedUsage: Scalars['Boolean'];
  vppUrl: Scalars['String'];
};

export enum ConfigureAddOnFailureReason {
  ExtendCart = 'ExtendCart',
  VoiceEquipment = 'VoiceEquipment',
}

export type ConfigureAddonInput = {
  productTypeId: Scalars['String'];
  shoppingCartId: Scalars['ID'];
  voice?: InputMaybe<Scalars['String']>;
};

export type ConfigureAddonPayload = {
  __typename?: 'ConfigureAddonPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ConfigureCartInput = {
  newProductTypeId: Scalars['ID'];
  portfolio: Scalars['String'];
};

export enum ContactMethodLabels {
  Billing = 'Billing',
  Invoice = 'Invoice',
  MobileNumber = 'MobileNumber',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Shipping = 'Shipping',
  Support = 'Support',
  WhatsApp = 'WhatsApp',
}

export enum ContactMethodType {
  AddressContactMethod = 'AddressContactMethod',
  EmailContactMethod = 'EmailContactMethod',
  PhoneContactMethod = 'PhoneContactMethod',
}

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Currency = {
  __typename?: 'Currency';
  alphabeticCode?: Maybe<Scalars['String']>;
  majorUnitSymbol?: Maybe<Scalars['String']>;
};

export type DeactivateStreamOnPayload = {
  __typename?: 'DeactivateStreamOnPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteOktaUserInput = {
  username: Scalars['String'];
};

export type DeleteOktaUserPayload = {
  __typename?: 'DeleteOktaUserPayload';
  success: Scalars['Boolean'];
};

export type DeleteUsernamePayload = {
  __typename?: 'DeleteUsernamePayload';
  errorMessage?: Maybe<Scalars['String']>;
  typeName: Scalars['String'];
  value: Scalars['String'];
};

export enum DeletionFailureReason {
  AccountActive = 'ACCOUNT_ACTIVE',
  AccountCreatedTooRecently = 'ACCOUNT_CREATED_TOO_RECENTLY',
}

export type Delivery = {
  __typename?: 'Delivery';
  code: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  status: Scalars['String'];
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  addressLine?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryCode: Scalars['String'];
  municipality: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
};

export enum DirectoryListType {
  Complex = 'COMPLEX',
  Error = 'ERROR',
  ListNotPublish = 'LIST_NOT_PUBLISH',
  ListPublish = 'LIST_PUBLISH',
  NotListNotPublish = 'NOT_LIST_NOT_PUBLISH',
  Ported = 'PORTED',
}

export type DroppedAddOns = {
  __typename?: 'DroppedAddOns';
  name: Scalars['String'];
  productTypeId: Scalars['String'];
};

export type EmailInput = {
  email: Scalars['String'];
  labels: Array<ContactMethodLabels>;
};

export type EmbeddedDataInput = {
  addOn: Scalars['String'];
  billingAccountNumber: Scalars['String'];
};

export type ExternalId = {
  __typename?: 'ExternalId';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FeesAndDiscounts = {
  __typename?: 'FeesAndDiscounts';
  discount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  kind: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type FilteredProductInstance = {
  __typename?: 'FilteredProductInstance';
  characteristics?: Maybe<Array<Maybe<Characteristics>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  productStartDate?: Maybe<Scalars['String']>;
};

export type FulfillmentAgreement = {
  __typename?: 'FulfillmentAgreement';
  fulfillmentAgreementId: Scalars['ID'];
};

export type FulfillmentAgreementId = {
  __typename?: 'FulfillmentAgreementId';
  fulfillmentAgreementId: Scalars['ID'];
};

export type GetAccountInfoPayload = {
  __typename?: 'GetAccountInfoPayload';
  address?: Maybe<AddressGroup>;
  email?: Maybe<Scalars['String']>;
  externalIdTypeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  partyId?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneGroup>;
  username?: Maybe<Scalars['String']>;
};

export type GetAllProductInstancesPayload = {
  __typename?: 'GetAllProductInstancesPayload';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  productInstanceId?: Maybe<Scalars['ID']>;
  productInstanceStatus?: Maybe<Scalars['String']>;
  productStartDate?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<FilteredProductInstance>>>;
};

export type GetAvailableInstallationDatesInput = {
  address_lines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country_code?: InputMaybe<Scalars['String']>;
  external_work_order_id?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  fulfillment_agreement_id?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  shopping_cart_id?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

export type GetAvailableInstallationDatesPayload = {
  __typename?: 'GetAvailableInstallationDatesPayload';
  schedules: Array<Maybe<Schedule>>;
  timezone: Scalars['String'];
};

export type GetAvailableTelephoneNumbersInput = {
  function_type?: InputMaybe<Scalars['String']>;
  max_results?: InputMaybe<Scalars['Int']>;
  postal_code: Scalars['String'];
};

export type GetBepInputsPayload = {
  __typename?: 'GetBEPInputsPayload';
  FOPartySummary: PartySummary;
  PSMPartySummary: PartySummary;
  accountHolderPartyId?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  billingContactPartyId?: Maybe<Scalars['String']>;
  nationalId?: Maybe<Scalars['String']>;
  partyId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<GetBepInputsPayloadProduct>>>;
  relnId?: Maybe<Scalars['String']>;
  relnTypeQualifiedName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  siteContactPartyId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type GetBepInputsPayloadProduct = {
  __typename?: 'GetBEPInputsPayloadProduct';
  hasCaf: Scalars['Boolean'];
  isACPSuspended: Scalars['Boolean'];
  isSoftSuspended: Scalars['Boolean'];
  kind: Scalars['String'];
  productInstanceId: Scalars['String'];
  productInstanceStatus?: Maybe<ProductInstanceStatus>;
  productTypeId: Scalars['String'];
};

export type GetBoletoValueInput = {
  invoiceNum?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  txnAmount: MoneyInput;
  txnDueDate: Scalars['String'];
  txnType: Scalars['String'];
};

export type GetBroadbandLabelAttributesPayload = {
  __typename?: 'GetBroadbandLabelAttributesPayload';
  modemDeviceModel?: Maybe<Scalars['String']>;
  satelliteName?: Maybe<Scalars['String']>;
  serviceAreaId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  upi?: Maybe<Scalars['String']>;
};

export type GetBuyMoreHistoryPayload = {
  __typename?: 'GetBuyMoreHistoryPayload';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  productInstanceId?: Maybe<Scalars['ID']>;
  productInstanceStatus?: Maybe<Scalars['String']>;
  productStartDate?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<BuyMoreProducts>>>;
};

export type GetBuyMoreInput = {
  languageCode?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  productInstanceId: Scalars['String'];
};

export type GetBuyMorePayload = {
  __typename?: 'GetBuyMorePayload';
  amount: Scalars['Int'];
  dataUnits?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclosure: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  priceText?: Maybe<Scalars['String']>;
  savings?: Maybe<Scalars['Float']>;
  savingsText?: Maybe<Scalars['String']>;
};

export type GetCurrentAddOnsInput = {
  addOnOrderId?: InputMaybe<Scalars['String']>;
};

export type GetCurrentAddOnsPayload = {
  __typename?: 'GetCurrentAddOnsPayload';
  description: Scalars['String'];
  discountAmount?: Maybe<Scalars['String']>;
  discountEndDate?: Maybe<Scalars['String']>;
  discountName?: Maybe<Scalars['String']>;
  isBundle: Scalars['Boolean'];
  kind: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  priceWithDiscount?: Maybe<Scalars['String']>;
  productInstanceId: Scalars['ID'];
  state: Scalars['String'];
};

export type GetCurrentPlanPricePayload = {
  __typename?: 'GetCurrentPlanPricePayload';
  price: Scalars['String'];
};

export type GetCurrentUsagePayload = {
  __typename?: 'GetCurrentUsagePayload';
  buyMoreDataCap: Scalars['Float'];
  buyMoreDataRemaining: Scalars['Float'];
  buyMoreDataUsed: Scalars['Float'];
  buyMoreDataUsedRatio: Scalars['Float'];
  daysLeft?: Maybe<Scalars['Float']>;
  displayedTotalDataCap: Scalars['Float'];
  displayedTotalDataUsed: Scalars['Float'];
  endDate?: Maybe<Scalars['String']>;
  freeZoneDataUsed: Scalars['Float'];
  hasBreachedDataAllowance: Scalars['Boolean'];
  monthlyDataCap: Scalars['Float'];
  monthlyDataRemaining: Scalars['Float'];
  monthlyDataUsed: Scalars['Float'];
  monthlyDataUsedRatio: Scalars['Float'];
  standardDataUsed: Scalars['Float'];
  startDate?: Maybe<Scalars['String']>;
};

export type GetDownloadSpeedsPayload = {
  __typename?: 'GetDownloadSpeedsPayload';
  downloadRangeText: Scalars['String'];
  downloadSpeed: Scalars['String'];
  downloadSpeedText: Scalars['String'];
  downloadUnits: Scalars['String'];
  id: Scalars['ID'];
};

export type GetHasPendingTransitionPayload = {
  __typename?: 'GetHasPendingTransitionPayload';
  hasActivationError?: Maybe<Scalars['Boolean']>;
  isChangePlanDisabled?: Maybe<Scalars['Boolean']>;
};

export type GetHistoricalUsageByBillCyclePayload = {
  __typename?: 'GetHistoricalUsageByBillCyclePayload';
  billCycles?: Maybe<Array<Maybe<BillCycleData>>>;
};

export type GetHistoricalUsageByDatePayload = {
  __typename?: 'GetHistoricalUsageByDatePayload';
  intervalEnd?: Maybe<Scalars['String']>;
  intervalStart?: Maybe<Scalars['String']>;
  usageData?: Maybe<Array<Maybe<UsagePerDay>>>;
};

export type GetHistoricalUsageByDeviceInput = {
  endDate: Scalars['String'];
  grain: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetHistoricalUsageByDevicePayload = {
  __typename?: 'GetHistoricalUsageByDevicePayload';
  devices?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['String']>;
  productInstanceId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalUsageGB?: Maybe<Scalars['Float']>;
  totals?: Maybe<Scalars['JSON']>;
};

export type GetInvoiceHistoryPayload = {
  __typename?: 'GetInvoiceHistoryPayload';
  currentMonthlyBill?: Maybe<Scalars['Float']>;
  error?: Maybe<StreamError>;
};

export type GetOutageNotificationsPayload = {
  __typename?: 'GetOutageNotificationsPayload';
  outages?: Maybe<Array<OutageNotification>>;
};

export type GetPendingInstallationDetailsPayload = {
  __typename?: 'GetPendingInstallationDetailsPayload';
  externalWorkOrderId?: Maybe<Scalars['String']>;
  hasPendingInstallation: Scalars['Boolean'];
  isEquipmentShipped: Scalars['Boolean'];
  newOfferName?: Maybe<Scalars['String']>;
  schedule?: Maybe<Schedule>;
};

export type GetPlanCharacteristicsPayload = {
  __typename?: 'GetPlanCharacteristicsPayload';
  bestFor: Scalars['String'];
  dataAllowanceText: Scalars['String'];
  dataCap: Scalars['String'];
  dataCapUnits: Scalars['String'];
  family: Scalars['String'];
  freeZoneTime: Scalars['String'];
  hasUnlimitedUsageMeter: Scalars['Boolean'];
  id: Scalars['String'];
  inflectionPoint: Scalars['String'];
  inflectionPointText: Scalars['String'];
  isBuyMoreAvailable: Scalars['Boolean'];
  isGoodFor: Scalars['String'];
  isUnleashed: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  name: Scalars['String'];
  titleAddOns: Scalars['String'];
  uploadSpeed: Scalars['String'];
  uploadSpeedText: Scalars['String'];
  usageMeter: Scalars['String'];
};

export type GetPlanOffersInput = {
  languageCode?: InputMaybe<Scalars['String']>;
};

export type GetPlanOffersPayload = {
  __typename?: 'GetPlanOffersPayload';
  characteristics: PlanCharacteristics;
  description?: Maybe<Scalars['String']>;
  disclosure: Scalars['String'];
  id: Scalars['ID'];
  isEquipmentChangeNeeded: Scalars['Boolean'];
  isUnlimited: Scalars['Boolean'];
  offerId: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
  savings?: Maybe<Scalars['Float']>;
};

export type GetProductInstancesPayload = {
  __typename?: 'GetProductInstancesPayload';
  productInstanceRelationships?: Maybe<
    Array<Maybe<ProductInstanceRelationship>>
  >;
  productInstances?: Maybe<Array<Maybe<ProductInstance>>>;
};

export type GetProfileInfoPayload = {
  accountNumber: Scalars['String'];
  accountType: AccountType;
  address: AddressGroup;
  isPreInstall?: Maybe<Scalars['Boolean']>;
  partyId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type GetScrubbedAddressInput = {
  addressLines: Array<Scalars['String']>;
  countryCode: Scalars['String'];
  municipality: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
};

export type GetScrubbedAddressPayload = {
  __typename?: 'GetScrubbedAddressPayload';
  addressLines: Array<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  municipality: Scalars['String'];
  postalCode: Scalars['String'];
  processStatus: Scalars['String'];
  recommendation: Scalars['String'];
  region: Scalars['String'];
};

export type GetStreamOnStatusPayload = {
  __typename?: 'GetStreamOnStatusPayload';
  macAddress?: Maybe<Scalars['String']>;
  shippingInfo?: Maybe<ShippingInfo>;
  status?: Maybe<StreamOnStatus>;
  zeroRatedGB?: Maybe<Scalars['Float']>;
};

export type GetTokenUsingCodeInput = {
  code: Scalars['String'];
  env?: InputMaybe<MvEnv>;
  locale?: InputMaybe<Locale>;
  platform: Platform;
};

export type GetTokenUsingCodePayload = {
  __typename?: 'GetTokenUsingCodePayload';
  accessToken: Scalars['String'];
  accessTokenExpirationTime: Scalars['String'];
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type GetTokenUsingRefreshInput = {
  locale?: InputMaybe<Locale>;
  platform: Platform;
  refreshToken: Scalars['String'];
};

export type GetTokenUsingRefreshPayload = {
  __typename?: 'GetTokenUsingRefreshPayload';
  accessToken: Scalars['String'];
  accessTokenExpirationTime: Scalars['String'];
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type GetTransactionIdInput = {
  invoiceNumber?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
  txnAmount?: InputMaybe<MoneyInput>;
  userAgent?: InputMaybe<Scalars['String']>;
};

export type GetUsageTierPayload = {
  __typename?: 'GetUsageTierPayload';
  tier: Scalars['Int'];
};

export type GetUserInfoPayload = {
  __typename?: 'GetUserInfoPayload';
  accountHolderPartyId?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressGroup>;
  billingContactPartyId?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isPreInstall?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  nationalId?: Maybe<Scalars['String']>;
  partyId?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneGroup>;
  productInstanceId?: Maybe<Scalars['String']>;
  productInstanceStatus?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductInstance>>>;
  relnId?: Maybe<Scalars['String']>;
  serviceAddress?: Maybe<Address>;
  siteContactPartyId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type HandleStagedUsersInput = {
  locale: Locale;
  username: Scalars['String'];
};

export type HandleStagedUsersPayload = {
  __typename?: 'HandleStagedUsersPayload';
  deletionFailureReason?: Maybe<DeletionFailureReason>;
  userDeleted: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountNumber?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Money>;
  amountOwedOnInvoice?: Maybe<Money>;
  badgeStatus?: Maybe<BadgeStatus>;
  billingCycleDate?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  electronicInvoiceAvailable?: Maybe<Scalars['Boolean']>;
  invoiceAmount?: Maybe<Money>;
  invoiceNetAmount?: Maybe<Money>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoicePDFUrl?: Maybe<Scalars['String']>;
  invoiceSettlementStatus?: Maybe<SettlementStatus>;
  invoiceStatus?: Maybe<InvoiceStatus>;
  invoiceTaxAmount?: Maybe<Money>;
  isBoletoLoading?: Maybe<Scalars['Boolean']>;
  previousBalance?: Maybe<Money>;
  statementDate?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
  Unpaid = 'UNPAID',
}

export enum Locale {
  EnUs = 'en_US',
  EsMx = 'es_MX',
  ItIt = 'it_IT',
  PtBr = 'pt_BR',
}

export enum MvEnv {
  Dev = 'dev',
  DevLocal = 'devLocal',
  Preprod = 'preprod',
  Prod = 'prod',
  Test = 'test',
}

export type MakePayment = {
  __typename?: 'MakePayment';
  error?: Maybe<VppError>;
  success: Scalars['Boolean'];
};

export type MakePaymentInput = {
  invoiceNumber?: InputMaybe<Scalars['String']>;
  txnAmount?: InputMaybe<MoneyInput>;
  userAgent: Scalars['String'];
};

export type MarketingCopy = {
  __typename?: 'MarketingCopy';
  translations: Array<ProductTypeTranslations>;
  ui_behaviors: UiBehaviors;
};

export type ModifyTransactionInput = {
  additionalDetails: AdditionalDetails;
  paymentSource: Scalars['String'];
  transactionId: Scalars['ID'];
  txnAmount: Scalars['Float'];
  txnType: Scalars['String'];
  userAgent: Scalars['String'];
};

export type ModifyTransactionPayload = {
  __typename?: 'ModifyTransactionPayload';
  errorCode?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Money = {
  __typename?: 'Money';
  alphabeticCode?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type MoneyInput = {
  alphabeticCode?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  configureAddon?: Maybe<ConfigureAddonPayload>;
  configureCart?: Maybe<ShoppingCartResponse>;
  deactivateStreamOn?: Maybe<DeactivateStreamOnPayload>;
  deleteOktaUser: DeleteOktaUserPayload;
  deleteUsername?: Maybe<DeleteUsernamePayload>;
  handleStagedUsers: HandleStagedUsersPayload;
  log?: Maybe<Scalars['Boolean']>;
  makePayment?: Maybe<MakePayment>;
  modifyTransaction?: Maybe<ModifyTransactionPayload>;
  purchaseAddOn?: Maybe<PurchaseAddOnPayload>;
  purchaseBuyMore?: Maybe<PurchaseBuyMorePayload>;
  rebootModem: RebootModemPayload;
  removeAddOn?: Maybe<RemoveAddOnPayload>;
  removeExternalId?: Maybe<RemoveExternalIdPayload>;
  reserveTelephoneNumber?: Maybe<ReserveTelephoneNumberPayload>;
  sendSurveyResultsToQualtrics?: Maybe<SurveyResultsPayload>;
  submitFullOrder?: Maybe<SubmitFullOrderPayload>;
  transitionPlan?: Maybe<TransitionPlanPayload>;
  updateAccountInfo?: Maybe<UpdateAccountInfoPayload>;
  updateAccountInfoSMB?: Maybe<UpdateAccountInfoSmbPayload>;
  updateBillCycleDay?: Maybe<BillingAccount>;
  updateOktaLocale: UpdateOktaLocalePayload;
  updatePhoneNumber: UpdatePhoneNumberPayload;
  updatePushNotificationStatus?: Maybe<UpdatePushNotificationStatusPayload>;
  updateRegistrationData: UpdateRegistrationDataPayload;
  upsertWorkOrderSchedule?: Maybe<UpsertWorkOrderSchedulePayload>;
  verifyAndSaveACP?: Maybe<VerifyAndSaveAcpPayload>;
};

export type MutationConfigureAddonArgs = {
  input: ConfigureAddonInput;
};

export type MutationConfigureCartArgs = {
  input: ConfigureCartInput;
};

export type MutationDeleteOktaUserArgs = {
  input?: InputMaybe<DeleteOktaUserInput>;
};

export type MutationDeleteUsernameArgs = {
  partyId: Scalars['ID'];
  typeName: Scalars['ID'];
  value: Scalars['String'];
};

export type MutationHandleStagedUsersArgs = {
  input?: InputMaybe<HandleStagedUsersInput>;
};

export type MutationLogArgs = {
  info: Scalars['JSON'];
  isError: Scalars['Boolean'];
  message: Scalars['String'];
};

export type MutationMakePaymentArgs = {
  input: MakePaymentInput;
};

export type MutationModifyTransactionArgs = {
  input: ModifyTransactionInput;
};

export type MutationPurchaseAddOnArgs = {
  input: PurchaseAddOnInput;
};

export type MutationPurchaseBuyMoreArgs = {
  input: PurchaseBuyMoreInput;
};

export type MutationRemoveAddOnArgs = {
  input?: InputMaybe<RemoveAddOnInput>;
};

export type MutationRemoveExternalIdArgs = {
  partyId: Scalars['ID'];
  typeName: Scalars['ID'];
  value: Scalars['String'];
};

export type MutationReserveTelephoneNumberArgs = {
  input: ReserveTelephoneNumberInput;
};

export type MutationSendSurveyResultsToQualtricsArgs = {
  input: SendSurveyResultsToQualtricsInput;
};

export type MutationSubmitFullOrderArgs = {
  input?: InputMaybe<SubmitFullOrderInput>;
};

export type MutationTransitionPlanArgs = {
  input: TransitionPlanInput;
};

export type MutationUpdateAccountInfoArgs = {
  input: UpdateAccountInfoInput;
};

export type MutationUpdateAccountInfoSmbArgs = {
  input: UpdateAccountInfoSmbInput;
};

export type MutationUpdateBillCycleDayArgs = {
  billingCycleDayOfMonth: Scalars['Int'];
};

export type MutationUpdateOktaLocaleArgs = {
  input: UpdateOktaLocaleInput;
};

export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};

export type MutationUpdatePushNotificationStatusArgs = {
  input: UpdatePushNotificationStatusInput;
};

export type MutationUpdateRegistrationDataArgs = {
  input: UpdateRegistrationDataInput;
};

export type MutationUpsertWorkOrderScheduleArgs = {
  input: UpsertWorkOrderScheduleInput;
};

export type MutationVerifyAndSaveAcpArgs = {
  input?: InputMaybe<VerifyAndSaveAcpInput>;
};

export type NoteInput = {
  content?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  caption?: Maybe<Scalars['String']>;
  level: NotificationLevel;
  link?: Maybe<NotificationLink>;
  title: Scalars['String'];
};

export enum NotificationLevel {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export type NotificationLink = {
  __typename?: 'NotificationLink';
  label: Scalars['String'];
  url: Scalars['String'];
};

export type OAuthClient = {
  __typename?: 'OAuthClient';
  clientId: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  orderId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type OutageNotification = {
  __typename?: 'OutageNotification';
  level: NotificationLevel;
  type: OutageType;
};

export enum OutageStatus {
  Open = 'OPEN',
}

export enum OutageType {
  Other = 'OTHER',
  Weather = 'WEATHER',
}

export type PackageType = {
  __typename?: 'PackageType';
  id: Scalars['ID'];
};

export type PartySummary = {
  __typename?: 'PartySummary';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PartySummaryInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  barCode?: Maybe<Scalars['String']>;
  invoiceNum?: Maybe<Scalars['String']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  isRefund?: Maybe<Scalars['Boolean']>;
  last4Digits?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Money>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  txnStatus?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Requested = 'Requested',
}

export type PendingPayment = {
  __typename?: 'PendingPayment';
  isRecurring?: Maybe<Scalars['Boolean']>;
  isRefund?: Maybe<Scalars['Boolean']>;
  last4Digits?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Money>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  txnStatus?: Maybe<Scalars['String']>;
};

export type Person = {
  __typename?: 'Person';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PersonInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Phone = {
  __typename?: 'Phone';
  labels: Array<Maybe<Scalars['String']>>;
  phoneNumber: Scalars['String'];
};

export type PhoneGroup = {
  __typename?: 'PhoneGroup';
  mobile?: Maybe<PhoneType>;
  primary?: Maybe<Phone>;
  secondary?: Maybe<Phone>;
};

export type PhoneInput = {
  labels: Array<ContactMethodLabels>;
  phoneNumber: Scalars['String'];
};

export enum PhoneType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type PlanCharacteristics = {
  __typename?: 'PlanCharacteristics';
  bannerColor?: Maybe<Scalars['String']>;
  benefits?: Maybe<Scalars['String']>;
  bestFor?: Maybe<Scalars['String']>;
  dataAllowanceText?: Maybe<Scalars['String']>;
  dataCapGB: Scalars['String'];
  dataCapUnits: Scalars['String'];
  downloadRange: Scalars['String'];
  downloadRangeText: Scalars['String'];
  downloadSpeed: Scalars['String'];
  downloadUnits: Scalars['String'];
  family: Scalars['String'];
  inflectionPointText: Scalars['String'];
  isGoodFor?: Maybe<Scalars['String']>;
  offerName: Scalars['String'];
  serviceAreaId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  titleAddOns?: Maybe<Scalars['String']>;
  upi?: Maybe<Scalars['String']>;
  uploadRangeText: Scalars['String'];
  uploadSpeed: Scalars['String'];
  uploadUnits: Scalars['String'];
  usageMeter: Scalars['String'];
};

export enum Platform {
  Android = 'Android',
  Ios = 'IOS',
  Web = 'Web',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  portfolioItems: Array<PortfolioItem>;
  portfolioRelationships: Array<PortfolioRelationship>;
};

export type PortfolioItem = {
  __typename?: 'PortfolioItem';
  characteristics?: Maybe<Array<Characteristic>>;
  isRoot: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  productInstanceId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
};

export type PortfolioRelationship = {
  __typename?: 'PortfolioRelationship';
  destination: RelationshipItem;
  source: RelationshipItem;
};

export type Price = {
  __typename?: 'Price';
  amount?: Maybe<Amount>;
};

export type ProductInstance = {
  __typename?: 'ProductInstance';
  characteristics?: Maybe<Array<Maybe<Characteristic>>>;
  description?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  locationIds: Array<Scalars['String']>;
  name: Scalars['String'];
  partySummary?: Maybe<Array<PartySummary>>;
  productInstanceId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  state: Scalars['String'];
};

export type ProductInstanceRelationship = {
  __typename?: 'ProductInstanceRelationship';
  destinationProductInstance: ProductInstance;
  productInstanceRelationshipId: Scalars['ID'];
  productRelationshipType: ProductRelationshipType;
  sourceProductInstance: ProductInstance;
};

export enum ProductInstanceStatus {
  Activating = 'ACTIVATING',
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Deactivated = 'DEACTIVATED',
  Error = 'ERROR',
  Other = 'OTHER',
  Preinstall = 'PREINSTALL',
  Suspended = 'SUSPENDED',
}

export enum ProductRelationshipType {
  Configures = 'CONFIGURES',
  DependsOn = 'DEPENDS_ON',
  EquipmentOf = 'EQUIPMENT_OF',
  Fulfills = 'FULFILLS',
  Replaces = 'REPLACES',
  RequiredBy = 'REQUIRED_BY',
}

export type ProductType = {
  __typename?: 'ProductType';
  characteristics?: Maybe<Array<Characteristic>>;
  description: Scalars['String'];
  discounts?: Maybe<ProductTypeDiscounts>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  marketing_copy: MarketingCopy;
  name: Scalars['String'];
  offer_id: Scalars['ID'];
  package_types?: Maybe<Array<PackageType>>;
  prices?: Maybe<Array<ProductTypePrice>>;
};

export type ProductTypeDiscount = {
  __typename?: 'ProductTypeDiscount';
  amount?: Maybe<ProductTypeDiscountAmount>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ProductTypeDiscountAmount = {
  __typename?: 'ProductTypeDiscountAmount';
  currency: ProductTypeDiscountCurrency;
  value: Scalars['Float'];
};

export type ProductTypeDiscountCurrency = {
  __typename?: 'ProductTypeDiscountCurrency';
  alphabetic_code?: Maybe<Scalars['String']>;
  major_unit_symbol?: Maybe<Scalars['String']>;
  minor_units?: Maybe<Scalars['Int']>;
  numeric_code?: Maybe<Scalars['Int']>;
};

export type ProductTypeDiscounts = {
  __typename?: 'ProductTypeDiscounts';
  itemized_discounts: Array<Maybe<ProductTypeDiscount>>;
  total_discounts?: Maybe<ProductTypeDiscount>;
};

export type ProductTypePrice = {
  __typename?: 'ProductTypePrice';
  amount: ProductTypePriceAmount;
};

export type ProductTypePriceAmount = {
  __typename?: 'ProductTypePriceAmount';
  currency: Currency;
  value: Scalars['Float'];
};

export type ProductTypeTranslations = {
  __typename?: 'ProductTypeTranslations';
  characteristics: Array<Characteristic>;
  language: Scalars['String'];
};

export type PurchaseAddOnInput = {
  productTypeId: Scalars['ID'];
  shoppingCartId: Scalars['ID'];
  voice?: InputMaybe<Scalars['String']>;
};

export type PurchaseAddOnPayload = {
  __typename?: 'PurchaseAddOnPayload';
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PurchaseBuyMoreInput = {
  productTypeId: Scalars['String'];
};

export type PurchaseBuyMorePayload = {
  __typename?: 'PurchaseBuyMorePayload';
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  checkStreamEligibility?: Maybe<CheckStreamEligibilityPayload>;
  config: ConfidantConfig;
  getAccountInfo?: Maybe<GetAccountInfoPayload>;
  getAddOnOffers: Array<ProductType>;
  getAggregatedBillingInfo?: Maybe<AggregatedBillingInfo>;
  getAllProductInstances?: Maybe<Array<Maybe<GetAllProductInstancesPayload>>>;
  getAutopayMethod?: Maybe<AutopayMethod>;
  getAvailableInstallationDates?: Maybe<GetAvailableInstallationDatesPayload>;
  getAvailableTelephoneNumbers?: Maybe<Array<Maybe<AvailablePhoneNumber>>>;
  getBEPInputs?: Maybe<GetBepInputsPayload>;
  getBillCycleDays: BillCycleDaysPayload;
  getBillingAccount?: Maybe<BillingAccount>;
  getBoletoValue?: Maybe<Boleto>;
  getBroadbandLabelAttributes?: Maybe<GetBroadbandLabelAttributesPayload>;
  getBuyMore?: Maybe<Array<Maybe<GetBuyMorePayload>>>;
  getBuyMoreHistory?: Maybe<GetBuyMoreHistoryPayload>;
  getCurrentAddOns: Array<Maybe<GetCurrentAddOnsPayload>>;
  getCurrentPlanPrice?: Maybe<GetCurrentPlanPricePayload>;
  getCurrentUsage?: Maybe<GetCurrentUsagePayload>;
  getDiagnostics?: Maybe<Array<Assessment>>;
  getDownloadSpeeds?: Maybe<GetDownloadSpeedsPayload>;
  getHasPendingTransition?: Maybe<GetHasPendingTransitionPayload>;
  getHistoricalUsageByBillCycle?: Maybe<GetHistoricalUsageByBillCyclePayload>;
  getHistoricalUsageByDate?: Maybe<GetHistoricalUsageByDatePayload>;
  getHistoricalUsageByDevice?: Maybe<GetHistoricalUsageByDevicePayload>;
  getInstallationWorkOrder?: Maybe<WorkOrderDetails>;
  getInvoiceHistory?: Maybe<GetInvoiceHistoryPayload>;
  getInvoicePDF?: Maybe<GetInvoicePdfPayload>;
  getInvoices?: Maybe<Array<Invoice>>;
  getMaintenanceStatus: GetMaintenanceStatusPayload;
  getModemStatus?: Maybe<GetModemStatusPayload>;
  getOfferDetails?: Maybe<StreamOnProduct>;
  getOrdersByCustomerRelationshipId?: Maybe<Array<Order>>;
  getOutageNotifications?: Maybe<GetOutageNotificationsPayload>;
  getPaymentHistory?: Maybe<Array<Payment>>;
  getPendingInstallationDetails: GetPendingInstallationDetailsPayload;
  getPendingPayments?: Maybe<Array<PendingPayment>>;
  getPlanCharacteristics?: Maybe<GetPlanCharacteristicsPayload>;
  getPlanOffers?: Maybe<Array<Maybe<GetPlanOffersPayload>>>;
  getPortfolio?: Maybe<Portfolio>;
  getProfileInfo?: Maybe<GetProfileInfoPayload>;
  getRefreshContext: Scalars['Boolean'];
  getRegistrationConfig: RegistrationConfig;
  getScrubbedAddress?: Maybe<GetScrubbedAddressPayload>;
  getStreamOnStatus?: Maybe<GetStreamOnStatusPayload>;
  getTokenInfo?: Maybe<TokenInfo>;
  getTokenUsingCode: GetTokenUsingCodePayload;
  getTokenUsingRefresh: GetTokenUsingRefreshPayload;
  getTransactionId?: Maybe<TransactionId>;
  getUsageTier?: Maybe<GetUsageTierPayload>;
  getUserDetails?: Maybe<UserDetails>;
  getUserInfo?: Maybe<GetUserInfoPayload>;
  getWorkOrderFulfillmentAgreementId?: Maybe<FulfillmentAgreementId>;
  getWorkOrders?: Maybe<Array<SummarizedWorkOrder>>;
  sendBoletoEmail?: Maybe<Scalars['String']>;
  validateAccountInfo: ValidateAccountInfoPayload;
  verifyToken: VerifyTokenPayload;
};

export type QueryGetAccountInfoArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetAggregatedBillingInfoArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetAllProductInstancesArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetAutopayMethodArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetAvailableInstallationDatesArgs = {
  input?: InputMaybe<GetAvailableInstallationDatesInput>;
};

export type QueryGetAvailableTelephoneNumbersArgs = {
  input?: InputMaybe<GetAvailableTelephoneNumbersInput>;
};

export type QueryGetBepInputsArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetBillingAccountArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetBoletoValueArgs = {
  input: GetBoletoValueInput;
};

export type QueryGetBuyMoreArgs = {
  input: GetBuyMoreInput;
};

export type QueryGetBuyMoreHistoryArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetCurrentAddOnsArgs = {
  input?: InputMaybe<GetCurrentAddOnsInput>;
};

export type QueryGetCurrentUsageArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetDiagnosticsArgs = {
  doRefresh?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetDownloadSpeedsArgs = {
  productTypeId?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetHasPendingTransitionArgs = {
  productInstanceId?: InputMaybe<Scalars['ID']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetHistoricalUsageByBillCycleArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetHistoricalUsageByDateArgs = {
  end?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['String']>;
};

export type QueryGetHistoricalUsageByDeviceArgs = {
  input: GetHistoricalUsageByDeviceInput;
};

export type QueryGetInstallationWorkOrderArgs = {
  externalWorkOrderId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetInvoicePdfArgs = {
  input: GetInvoicePdfInput;
};

export type QueryGetInvoicesArgs = {
  nextBillPeriodStartDate?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetModemStatusArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPaymentHistoryArgs = {
  nextBillPeriodStartDate?: InputMaybe<Scalars['String']>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPendingInstallationDetailsArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPlanCharacteristicsArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetPlanOffersArgs = {
  input: GetPlanOffersInput;
};

export type QueryGetProfileInfoArgs = {
  refetchData?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetRegistrationConfigArgs = {
  locale: Locale;
};

export type QueryGetScrubbedAddressArgs = {
  address: GetScrubbedAddressInput;
};

export type QueryGetTokenInfoArgs = {
  locale?: InputMaybe<Locale>;
  token: Scalars['String'];
};

export type QueryGetTokenUsingCodeArgs = {
  input: GetTokenUsingCodeInput;
};

export type QueryGetTokenUsingRefreshArgs = {
  input: GetTokenUsingRefreshInput;
};

export type QueryGetTransactionIdArgs = {
  input?: InputMaybe<GetTransactionIdInput>;
};

export type QueryGetUserInfoArgs = {
  locale?: InputMaybe<Locale>;
  refetchData?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryGetWorkOrderFulfillmentAgreementIdArgs = {
  externalWorkOrderId?: InputMaybe<Scalars['ID']>;
};

export type QuerySendBoletoEmailArgs = {
  invoiceNumber?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};

export type QueryValidateAccountInfoArgs = {
  input?: InputMaybe<ValidateAccountInfoInput>;
};

export type QueryVerifyTokenArgs = {
  partyId?: InputMaybe<Scalars['String']>;
  productInstanceId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RebootModemPayload = {
  __typename?: 'RebootModemPayload';
  rebootSuccess: Scalars['Boolean'];
};

export type RegistrationConfig = {
  __typename?: 'RegistrationConfig';
  client: OAuthClient;
  issuer: Scalars['String'];
  myViasatUrl: Scalars['String'];
};

export type RelationshipItem = {
  __typename?: 'RelationshipItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RemoveAddOnInput = {
  addOnProductInstanceId: Scalars['String'];
};

export type RemoveAddOnPayload = {
  __typename?: 'RemoveAddOnPayload';
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RemoveExternalIdPayload = {
  __typename?: 'RemoveExternalIdPayload';
  typeName: Scalars['String'];
  value: Scalars['String'];
};

export type ReserveTelephoneNumberInput = {
  telephoneNumber: Scalars['String'];
};

export type ReserveTelephoneNumberPayload = {
  __typename?: 'ReserveTelephoneNumberPayload';
  reservedPhoneNumber?: Maybe<Scalars['String']>;
  reservedTime?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ResidentialProfileInfo = GetProfileInfoPayload & {
  __typename?: 'ResidentialProfileInfo';
  accountNumber: Scalars['String'];
  accountType: AccountType;
  address: AddressGroup;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isACPEligible?: Maybe<Scalars['Boolean']>;
  isPreInstall?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  partyId: Scalars['String'];
  phone?: Maybe<PhoneGroup>;
  username?: Maybe<Scalars['String']>;
};

export enum Role {
  AccountHolder = 'AccountHolder',
  BillingContact = 'BillingContact',
  Customer = 'Customer',
  SiteContact = 'SiteContact',
}

export type SmbContact = {
  __typename?: 'SMBContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  partyId?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneGroup>;
};

export type SmbProfileInfo = GetProfileInfoPayload & {
  __typename?: 'SMBProfileInfo';
  accountHolder?: Maybe<SmbContact>;
  accountNumber: Scalars['String'];
  accountType: AccountType;
  address: AddressGroup;
  billingContact?: Maybe<SmbContact>;
  businessName?: Maybe<Scalars['String']>;
  isPreInstall?: Maybe<Scalars['Boolean']>;
  partyId: Scalars['String'];
  siteContact?: Maybe<SmbContact>;
  username?: Maybe<Scalars['String']>;
};

export type Schedule = {
  __typename?: 'Schedule';
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type ScheduleInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type SendSurveyResultsToQualtricsInput = {
  embeddedData: EmbeddedDataInput;
  firstQuestionAnswer?: InputMaybe<Scalars['String']>;
  secondQuestionAnswer?: InputMaybe<Scalars['String']>;
};

export enum SettlementStatus {
  Issued = 'Issued',
  NoPaymentDue = 'NoPaymentDue',
  Paid = 'Paid',
  PastDue = 'PastDue',
}

export type ShippingInfo = {
  __typename?: 'ShippingInfo';
  delivery: Delivery;
  toAddress: DeliveryAddress;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
};

export type ShoppingCartPrices = {
  __typename?: 'ShoppingCartPrices';
  monthly: ShoppingCartPricesMonthly;
  oneTime: ShoppingCartPricesOneTime;
};

export type ShoppingCartPricesMonthly = {
  __typename?: 'ShoppingCartPricesMonthly';
  lineItems?: Maybe<LineItemsMonthly>;
  totalWithPromos?: Maybe<Scalars['Float']>;
  totalWithoutPromos?: Maybe<Scalars['Float']>;
};

export type ShoppingCartPricesOneTime = {
  __typename?: 'ShoppingCartPricesOneTime';
  feesAndDiscounts?: Maybe<Array<FeesAndDiscounts>>;
  lease?: Maybe<Scalars['Float']>;
  lineItems?: Maybe<LineItemsOneTime>;
  totalWithPromos?: Maybe<Scalars['Float']>;
  totalWithoutPromos?: Maybe<Scalars['Float']>;
};

export type ShoppingCartResponse = {
  __typename?: 'ShoppingCartResponse';
  accountSetupShortName: Scalars['String'];
  cartItems: CartItems;
  droppedAddOns: Array<DroppedAddOns>;
  hasEquipmentShipping: Scalars['Boolean'];
  id: Scalars['ID'];
  leaseFeeShortName: Scalars['String'];
  prices: ShoppingCartPrices;
  requiresWorkOrder: Scalars['Boolean'];
  status: Scalars['String'];
};

export type StreamEligibilityError = {
  __typename?: 'StreamEligibilityError';
  failureReason?: Maybe<StreamEligibilityFailureReason>;
};

export enum StreamEligibilityFailureReason {
  DeactivatedProduct = 'deactivated_product',
  ErrorActivationProduct = 'error_activation_product',
  PendingActivationProduct = 'pending_activation_product',
  ProductNotEligible = 'product_not_eligible',
  SuspendedProduct = 'suspended_product',
}

export type StreamError = {
  __typename?: 'StreamError';
  failureReason?: Maybe<Scalars['String']>;
};

export type StreamOnProduct = {
  __typename?: 'StreamOnProduct';
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  buttonText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionShipping?: Maybe<Scalars['String']>;
  discountDuration?: Maybe<Scalars['Float']>;
  discountValue: Scalars['Float'];
  fullPrice?: Maybe<Scalars['String']>;
  hyperlinkText?: Maybe<Scalars['String']>;
  hyperlinkUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offerDescription?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  productFamily: Scalars['String'];
  productTypeId: Scalars['String'];
  shippingConfirmation?: Maybe<Scalars['String']>;
  shouldDisplaySms?: Maybe<Scalars['Boolean']>;
  smsFooter?: Maybe<Scalars['String']>;
  smsLabel?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleShipping?: Maybe<Scalars['String']>;
  tooltipShipping?: Maybe<Scalars['String']>;
};

export enum StreamOnStatus {
  Active = 'ACTIVE',
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  ErrorReplaceHub = 'ERROR_REPLACE_HUB',
  NeverPluggedIn = 'NEVER_PLUGGED_IN',
  Pending = 'PENDING',
  RequestErrored = 'REQUEST_ERRORED',
  Shipping = 'SHIPPING',
  Unplugged = 'UNPLUGGED',
}

export type StreamOnSubscriptionPayload = {
  __typename?: 'StreamOnSubscriptionPayload';
  datePublished: Scalars['String'];
  macAddress: Scalars['String'];
  status: Scalars['String'];
};

export type SubmitFullOrderInput = {
  address?: InputMaybe<AddressInput>;
  isEditingNumber?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type SubmitFullOrderPayload = {
  __typename?: 'SubmitFullOrderPayload';
  didOrderFail?: Maybe<Scalars['Boolean']>;
  error?: Maybe<StreamError>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
  streamOnSubscription: StreamOnSubscriptionPayload;
};

export type SubscriptionStreamOnSubscriptionArgs = {
  macAddress: Scalars['String'];
};

export type SummarizedWorkOrder = {
  __typename?: 'SummarizedWorkOrder';
  externalWorkOrderId: Scalars['ID'];
  schedule?: Maybe<Schedule>;
  state?: Maybe<Scalars['String']>;
  workOrderType: Scalars['String'];
};

export type SurveyResultsPayload = {
  __typename?: 'SurveyResultsPayload';
  success: Scalars['Boolean'];
};

export type TokenInfo = {
  __typename?: 'TokenInfo';
  accessTokenExpirationTime?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
  uid?: Maybe<Scalars['String']>;
};

export enum TokenType {
  Okta = 'Okta',
  PartnerLogin = 'PartnerLogin',
  Registration = 'Registration',
  Salesforce = 'Salesforce',
  SignInViasatCom = 'SignInViasatCom',
  ViasatIo = 'ViasatIO',
}

export type TransactionId = {
  __typename?: 'TransactionId';
  transactionId?: Maybe<Scalars['String']>;
};

export enum TransactionTypeAcp {
  Enroll = 'Enroll',
  Transfer = 'Transfer',
}

export type TransitionPlanInput = {
  cartId: Scalars['ID'];
  currentProductTypeId?: InputMaybe<Scalars['String']>;
  newProductTypeId?: InputMaybe<Scalars['String']>;
  workOrderInformation?: InputMaybe<WorkOrderInformation>;
};

export type TransitionPlanPayload = {
  __typename?: 'TransitionPlanPayload';
  appointment?: Maybe<TransitionScheduleOutput>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TransitionSchedule = {
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type TransitionScheduleOutput = {
  __typename?: 'TransitionScheduleOutput';
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  workOrderScheduled: Scalars['Boolean'];
};

export type UiBehaviors = {
  __typename?: 'UIBehaviors';
  characteristics: Array<Characteristic>;
};

export type UpdateAccountInfoInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  deleteSecondaryPhone?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<EmailInput>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type UpdateAccountInfoPayload = {
  __typename?: 'UpdateAccountInfoPayload';
  address: AddressGroup;
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  phone: PhoneGroup;
};

export type UpdateAccountInfoSmbInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  deleteSecondaryPhone?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<EmailInput>;
  phones?: InputMaybe<Array<PhoneInput>>;
  role: Role;
};

export type UpdateAccountInfoSmbPayload = {
  __typename?: 'UpdateAccountInfoSMBPayload';
  address: AddressGroup;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone: PhoneGroup;
};

export type UpdateOktaLocaleInput = {
  locale: Locale;
  username: Scalars['String'];
  uuid: Scalars['String'];
};

export type UpdateOktaLocalePayload = {
  __typename?: 'UpdateOktaLocalePayload';
  locale: Locale;
  username: Scalars['String'];
};

export type UpdatePhoneNumberInput = {
  accountNumber: Scalars['String'];
  locale: Locale;
  phoneNumber: Scalars['String'];
  username: Scalars['String'];
  uuid: Scalars['String'];
};

export type UpdatePhoneNumberPayload = {
  __typename?: 'UpdatePhoneNumberPayload';
  accountNumber: Scalars['String'];
  partyId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdatePushNotificationStatusInput = {
  newNotificationId?: InputMaybe<Scalars['String']>;
  oldNotificationId?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Platform>;
  shouldSubscribeToPushNotifications: Scalars['Boolean'];
};

export type UpdatePushNotificationStatusPayload = {
  __typename?: 'UpdatePushNotificationStatusPayload';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateRegistrationDataInput = {
  accountNumber: Scalars['String'];
  locale: Locale;
  oktaEmail: Scalars['String'];
  uuid: Scalars['String'];
};

export type UpdateRegistrationDataPayload = {
  __typename?: 'UpdateRegistrationDataPayload';
  accountNumber: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<ExternalId>;
  partyId?: Maybe<Scalars['String']>;
};

export type UpsertWorkOrderScheduleInput = {
  externalWorkOrderId: Scalars['ID'];
  note?: InputMaybe<NoteInput>;
  schedule: ScheduleInput;
};

export type UpsertWorkOrderSchedulePayload = {
  __typename?: 'UpsertWorkOrderSchedulePayload';
  externalWorkOrderId: Scalars['ID'];
  success: Scalars['Boolean'];
};

export type UsagePerDay = {
  __typename?: 'UsagePerDay';
  date?: Maybe<Scalars['String']>;
  usage?: Maybe<Scalars['Float']>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  email?: Maybe<Scalars['String']>;
  error?: Maybe<StreamError>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
};

export type VppError = {
  __typename?: 'VPPError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ValidateAccountInfoInput = {
  accountNumber: Scalars['String'];
  locale: Locale;
  postalCode: Scalars['String'];
};

export type ValidateAccountInfoPayload = {
  __typename?: 'ValidateAccountInfoPayload';
  accountNumberExistsInOkta: Scalars['Boolean'];
  locale?: Maybe<Locale>;
  token?: Maybe<Scalars['String']>;
  validAccountInfo: Scalars['Boolean'];
};

export type VerifyAndSaveAcpInput = {
  applicationId?: InputMaybe<Scalars['String']>;
  customerPartyId?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasTribalBenefit?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  repId?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionTypeAcp>;
};

export type VerifyAndSaveAcpPayload = {
  __typename?: 'VerifyAndSaveAcpPayload';
  corrective_action?: Maybe<Scalars['String']>;
  error_type?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  trace_id?: Maybe<Scalars['String']>;
};

export type VerifyTokenPayload = {
  __typename?: 'VerifyTokenPayload';
  accountNumber?: Maybe<Scalars['String']>;
  displayLanguage?: Maybe<Scalars['String']>;
  expiration: Scalars['Int'];
  locale?: Maybe<Locale>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<TokenType>;
  uid: Scalars['String'];
};

export enum VoiceAddonPhoneNumberType {
  New = 'NEW',
  Port = 'PORT',
}

export type WorkOrder = {
  __typename?: 'WorkOrder';
  schedule?: Maybe<Schedule>;
  state?: Maybe<Scalars['String']>;
  workOrderType?: Maybe<WorkOrderType>;
};

export type WorkOrderDetails = {
  __typename?: 'WorkOrderDetails';
  fulfillmentAgreement?: Maybe<FulfillmentAgreement>;
  workOrder?: Maybe<WorkOrder>;
};

export type WorkOrderInformation = {
  appointment?: InputMaybe<TransitionSchedule>;
  notes: Scalars['String'];
};

export type WorkOrderType = {
  __typename?: 'WorkOrderType';
  workOrderType?: Maybe<Scalars['String']>;
};

export type GetInvoicePdfInput = {
  invoiceDate: Scalars['String'];
};

export type GetInvoicePdfPayload = {
  __typename?: 'getInvoicePDFPayload';
  invoicePDF?: Maybe<Scalars['String']>;
};

export type GetMaintenanceStatusPayload = {
  __typename?: 'getMaintenanceStatusPayload';
  inMaintenance: Scalars['Boolean'];
};

export type GetModemStatusPayload = {
  __typename?: 'getModemStatusPayload';
  isOnline: Scalars['Boolean'];
};

export type LineItemsMonthly = {
  __typename?: 'lineItemsMonthly';
  lease?: Maybe<Scalars['Float']>;
  promo: Scalars['Float'];
};

export type LineItemsOneTime = {
  __typename?: 'lineItemsOneTime';
  promo?: Maybe<Scalars['Float']>;
};
