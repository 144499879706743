import {
  AemFeature,
  CommonQuestions,
  HelpPageSectionValue,
} from '@mfe/shared/components';

import styles from './home.module.scss';

const BillingCommonQuestions = () => {
  return (
    <div className={styles['commonQuestionsWrapper']}>
      <CommonQuestions
        feature={AemFeature.BILLING_COMMON_QUESTIONS}
        section={HelpPageSectionValue.Billing}
      />
    </div>
  );
};

export default BillingCommonQuestions;
