export enum EditableCardType {
  Billing = 'Billing',
  ContactInformation = 'ContactInformation',
  Shipping = 'Shipping',
  None = 'None',
}

export enum HelpPageSectionValue {
  All = 'All',
  Billing = 'Billing',
  Connectivity = 'Connectivity',
  Usage = 'Usage',
}
