import {
  AemFeature,
  CommonQuestions,
  HelpPageSectionValue,
} from '@mfe/shared/components';

export const UsageCommonQuestions = () => {
  return (
    <CommonQuestions
      feature={AemFeature.USAGE_COMMON_QUESTIONS}
      section={HelpPageSectionValue.Usage}
    />
  );
};
