import {
  AemFeature,
  CommonQuestions,
  HelpPageSectionValue,
} from '@mfe/shared/components';

import styles from './Overview.module.scss';

export const OverviewCommonQuestions = () => {
  return (
    <div className={styles['commonQuestionsMargin']}>
      <CommonQuestions
        feature={AemFeature.OVERVIEW_COMMON_QUESTIONS}
        section={HelpPageSectionValue.All}
      />
    </div>
  );
};
